<template>
  <base-section id="timeline" class="bac-section">
    <base-section-heading title="Timeline of Events">
      Things have been happening quickly, and often without most of Plymouth
      knowing about it. Here's a quick&nbsp;recap:
    </base-section-heading>

    <div style="display: none;">{{counter}}</div>

    <v-container style="max-width: 1200px;">
      <v-row>
        <v-col>
          <v-timeline :dense="$vuetify.breakpoint.smAndDown">
            <v-timeline-item
              :color="card.future ? '#0077B6' : '#03045E'"
              :fill-dot="card.future"
              :small="card.future"
              :class="card.dotIcon ? 'has-icon' : ''"
              :icon="card.dotIcon"
              :right="i % 2 === 0"
              v-for="(card, i) in filteredCards"
              :key="i"
            >
              <v-card v-if="!card.section">
                <v-card-title
                  v-if="card.icon || card.title"
                  class="py-2"
                  :style="
                    card.future
                      ? `background-color: #0077B6;`
                      : `background-color: #03045E;`
                  "
                >
                  <v-icon dark size="32" class="mr-3" v-if="card.icon">
                    {{ card.icon }}
                  </v-icon>
                  <div class="text-h5 white--text letter-reset">
                    <div v-html="card.title"></div>
                  </div>
                </v-card-title>
                <v-container>
                  <v-row>
                    <v-col class="px-4">
                      <div v-if="card.subtitle" style="position: relative;">
                        <v-avatar
                          size="36"
                          style="top: -7px; left: -4px; position: absolute;"
                        >
                          <img :src="card.subicon" />
                        </v-avatar>
                        <h3
                          v-html="card.subtitle"
                          :style="card.subicon ? 'padding-left: 40px;' : ''"
                        ></h3>
                        <v-divider class="my-2"></v-divider>
                      </div>
                      <div v-html="card.text"></div>
                      <v-btn
                        v-if="card.link"
                        color="#03045E"
                        class="px-4 mt-4 mb-1"
                        dark
                        @click="download(card.link, card.popupTitle)"
                      >
                        <v-icon class="mr-2">mdi-dock-window</v-icon>
                        {{ card.linkLabel }}
                      </v-btn>
                      <v-btn
                        v-if="card.link2"
                        color="#03045E"
                        class="px-4 mt-4 mb-1"
                        dark
                        @click="download(card.link2, card.popupTitle)"
                      >
                        <v-icon class="mr-2">mdi-dock-window</v-icon>
                        {{ card.linkLabel2 }}
                      </v-btn>
                      <v-btn
                        v-if="card.link3"
                        color="#03045E"
                        class="px-4 mt-4 mb-1"
                        dark
                        @click="download(card.link3, card.popupTitle)"
                      >
                        <v-icon class="mr-2">mdi-dock-window</v-icon>
                        {{ card.linkLabel3 }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
              <v-card v-if="card.section">
                <v-container>
                  <v-row>
                    <v-col class="px-4">
                      <div style="position: relative;">
                        <h3>
                          <!-- <v-icon v-if="!sections[card.section]" class="mr-2">mdi-eye-off-outline</v-icon> -->
                          <span v-html="card.section"></span>
                        </h3>
                        <v-divider class="my-2"></v-divider>
                      </div>
                      <v-btn
                      v-if="sections[card.section]"
                        color="#03045E"
                        class="px-4 mt-4 mb-1"
                        dark
                        @click="collapseSection(card.section)"
                      >
                        Hide {{getCount(card.section)}}
                      </v-btn>
                      <v-btn
                        v-if="!sections[card.section]"
                        color="#03045E"
                        class="px-4 mt-4 mb-1"
                        dark
                        @click="expandSection(card.section)"
                      >
                        Show {{getCount(card.section)}}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-timeline-item>
          </v-timeline>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
import timelineJson from "@/timeline.json";
export default {
  name: "Timeline",

  data: () => ({
    cards: timelineJson,
    sections: {},
    filteredCards: [],
    counter: 0
  }),
  methods: {
    getCount(section) {
      let count = 0;
      for (let card of this.cards) {
        if (card.parent === section) {
          count++;
        }
      }
      if (count === 1) {
        return "1 item";
      } else {
        return count + " items";
      }
    },
    download(link) {
      this.$hub.$emit("openPdf", link, "timeline");
    },
    collapseSection(section) {
      this.sections[section] = false;
      this.filterCards();
    },
    expandSection(section) {
      this.sections[section] = true;
      this.filterCards();
    },
    filterCards() {
      const cards = [];
      for (let card of this.cards) {
        if (!card.parent || this.sections[card.parent]) {
          cards.push(card);
        }
      }
      this.filteredCards = cards;
      this.counter++;
    }
  },
  mounted() {
    window.$t = this;
    for (let card of this.cards) {
        if (card.section && card.open) {
          this.sections[card.section] = true;
        }
    }
    this.filterCards();
  }
};
</script>

<style lang="scss">
#timeline {
  background-color: #90e0ef;
  .v-timeline-item.has-icon .v-timeline-item__dot {
    background: orange;
    .v-timeline-item__inner-dot .v-icon {
      opacity: 0.75;
      font-size: 20px;
      color: orange;
    }
  }
  .canceled {
    font-weight: 300 !important;
    font-size: 85%;
    letter-spacing: 0;
    right: 16px;
    display: inline-block;
    position: absolute;
    color: orange;
  }
}
</style>
